/* eslint-disable complexity */
import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { getCurrencySymbol } from '../../hooks/currencyUtils';
import { DailyAvailability } from '../../types';

interface PriceData {
  [key: string]: number;
}

interface CalendarProps {
  onDateSelect?: (date: string) => void;
  multiSelect?: boolean;
  initialDate?: Date;
  prices?: PriceData;
  selectedDate: string | null;
  setSelectedDate: (date: string | null) => void;
  availability?: DailyAvailability[];
  currency?: string;
  onChangeMonth?: (year: number, month: number) => void;
  currentDate?: Date;
}

type DaysInMonthType = {
  daysInMonth: number;
  startingDay: number;
};

const Calendar = ({
  initialDate = new Date(),
  prices = {},
  selectedDate,
  setSelectedDate,
  availability = [],
  currency = 'PLN',
  onChangeMonth,
  currentDate: controlledCurrentDate,
}: CalendarProps) => {
  // Jeśli currentDate jest przekazany, używamy go; w przeciwnym razie korzystamy z wewnętrznego stanu.
  const [internalDate, setInternalDate] = useState<Date>(initialDate);
  const currentDate = controlledCurrentDate || internalDate;

  const daysInWeek: string[] = ['pn', 'wt', 'śr', 'czw', 'pt', 'sob', 'nd'];
  const months: string[] = [
    'Styczeń',
    'Luty',
    'Marzec',
    'Kwiecień',
    'Maj',
    'Czerwiec',
    'Lipiec',
    'Sierpień',
    'Wrzesień',
    'Październik',
    'Listopad',
    'Grudzień',
  ];

  // Zwraca liczbę dni w miesiącu oraz indeks pierwszego dnia miesiąca (indeks w tygodniu)
  const getDaysInMonth = (date: Date): DaysInMonthType => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const startingDay = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;
    return { daysInMonth, startingDay };
  };

  // Formatujemy datę jako YYYY-MM-DD
  const formatLocalDate = (
    year: number,
    month: number,
    day: number
  ): string => {
    const formattedMonth = (month + 1).toString().padStart(2, '0');
    const formattedDay = day.toString().padStart(2, '0');
    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  // Pobiera dane dostępności dla konkretnego dnia
  const getAvailability = (day: number): DailyAvailability | undefined => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const dateStr = formatLocalDate(year, month, day);
    return availability.find((a) => a.date === dateStr);
  };

  // Sprawdza, czy na podstawie danych dostępności dzień jest dostępny
  const isDateAvailable = (day: number): boolean => {
    const availInfo = getAvailability(day);
    if (!availInfo) {
      return true;
    }
    if (availInfo.unlimited === true) {
      return true;
    }
    if (availInfo.type === 'WHOLE_DAY') {
      if (availInfo.maxCapacity === null) {
        return availInfo.soldSeats === null ? true : availInfo.unlimited;
      }
      return availInfo.soldSeats < availInfo.maxCapacity;
    } else if (availInfo.type === 'TIME_SLOT') {
      if (
        !availInfo.hourlyAvailability ||
        availInfo.hourlyAvailability.length === 0
      ) {
        return true;
      }
      return availInfo.hourlyAvailability.some(
        (slot) => slot.soldSeats < slot.maxCapacity
      );
    }
    return true;
  };

  // Oblicza liczbę dostępnych miejsc dla danego dnia
  const getAvailableSeats = (day: number): number | string | undefined => {
    const availInfo = getAvailability(day);
    if (!availInfo) {
      return undefined;
    }
    if (availInfo.unlimited === true) {
      return '∞';
    }
    if (availInfo.type === 'WHOLE_DAY') {
      if (availInfo.maxCapacity === null || availInfo.soldSeats === null) {
        return undefined;
      }
      return availInfo.maxCapacity - availInfo.soldSeats;
    } else if (availInfo.type === 'TIME_SLOT') {
      if (
        !availInfo.hourlyAvailability ||
        availInfo.hourlyAvailability.length === 0
      ) {
        return undefined;
      }
      const totalAvailable = availInfo.hourlyAvailability.reduce(
        (sum, slot) => sum + (slot.maxCapacity - slot.soldSeats),
        0
      );
      return totalAvailable;
    }
    return undefined;
  };

  // Nowa funkcja – sprawdza, czy dany dzień jest w przeszłości (w porównaniu do dzisiejszej daty)
  const isPastDay = (day: number): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const dayDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day
    );
    return dayDate < today;
  };

  // Kliknięcie dnia – blokujemy wybór, jeśli dzień jest w przeszłości
  const handleDateClick = (day: number): void => {
    if (isPastDay(day)) return;
    if (!isDateAvailable(day)) return;
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const clickedDate = formatLocalDate(year, month, day);
    setSelectedDate(clickedDate);
  };

  // Nawigacja pomiędzy miesiącami – blokujemy przejście do poprzedniego miesiąca, jeśli nowy miesiąc jest wcześniejszy niż bieżący miesiąc
  const navigateMonth = (direction: number): void => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() + direction);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const currentMonthStart = new Date(
      today.getFullYear(),
      today.getMonth(),
      1
    );
    if (newDate < currentMonthStart) return;
    if (!controlledCurrentDate) {
      setInternalDate(newDate);
    }
    onChangeMonth?.(newDate.getFullYear(), newDate.getMonth());
  };

  const { daysInMonth, startingDay } = getDaysInMonth(currentDate);
  const days: number[] = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  const currencySymbol = getCurrencySymbol(currency);

  return (
    <div className="w-full max-w-2xl mx-auto p-1 sm:p-4">
      <div className="flex items-center justify-center gap-8 mb-2">
        {/* Lewy przycisk – wyłączamy go, gdy jesteśmy w bieżącym miesiącu */}
        <button
          onClick={() => navigateMonth(-1)}
          disabled={
            currentDate.getFullYear() === new Date().getFullYear() &&
            currentDate.getMonth() === new Date().getMonth()
          }
          className="w-[20px] h-[20px] hover:bg-gray-100 rounded-full border-0 bg-[#E7EEF8] cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <ChevronLeft className="w-3 h-3" />
        </button>

        <h2 className="text-[18px] font-semibold">
          {months[currentDate.getMonth()]} {currentDate.getFullYear()}
        </h2>

        <button
          onClick={() => navigateMonth(1)}
          className="w-[20px] h-[20px] hover:bg-gray-100 rounded-full border-0 bg-[#E7EEF8] cursor-pointer"
        >
          <ChevronRight className="w-3 h-3" />
        </button>
      </div>

      <div className="grid grid-cols-7 gap-2">
        {daysInWeek.map((day) => (
          <div key={day} className="text-center text-sm text-[#8DA2C2]">
            {day}
          </div>
        ))}

        {Array.from({ length: startingDay }).map((_, index) => (
          <div key={`empty-${index}`} className="p-2" />
        ))}

        {days.map((day) => {
          const dateStr = formatLocalDate(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            day
          );
          const price = prices[dateStr];
          const isSelected = selectedDate === dateStr;
          const available = isDateAvailable(day);
          const past = isPastDay(day);
          const availableSeats = getAvailableSeats(day);
          return (
            <button
              key={day}
              onClick={() => handleDateClick(day)}
              disabled={!available || past}
              className={`
                p-[0.15rem] rounded-lg flex flex-col items-center justify-center gap-0
                transition-colors duration-200 border-0
                ${
                  isSelected
                    ? 'bg-[#222C4D] text-white'
                    : past
                    ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                    : available
                    ? 'bg-[#E7EEF8] hover:bg-gray-100 cursor-pointer'
                    : 'bg-gray-200 text-gray-400 cursor-not-allowed'
                }
              `}
            >
              <span className="text-[18px] font-semibold">{day}</span>
              {price && (
                <span
                  className={`text-[14px] font-light ${
                    isSelected
                      ? 'text-gray-200'
                      : past
                      ? 'text-gray-400'
                      : available
                      ? 'text-green-600'
                      : 'text-gray-400'
                  }`}
                >
                  {price} {currencySymbol}
                </span>
              )}
              {availableSeats !== undefined && (
                <span
                  className={`text-[11px] ${
                    isSelected
                      ? 'text-gray-200'
                      : past
                      ? 'text-gray-400'
                      : available
                      ? availableSeats === '∞'
                        ? 'text-green-500'
                        : typeof availableSeats === 'number' &&
                          availableSeats < 10
                        ? 'text-red-500'
                        : 'text-blue-500'
                      : 'text-gray-400'
                  }`}
                >
                  {availableSeats === '∞'
                    ? 'Bez limitu'
                    : typeof availableSeats === 'number' && availableSeats > 0
                    ? `Zostało: ${availableSeats}`
                    : 'Brak miejsc'}
                </span>
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Calendar;
