import { BergStepper } from '@berg-regions/berg-components';
import './CheckoutProcessWrapper.scss';
import { useTheme } from '../../context/ThemeContext';

interface IProps extends React.ComponentPropsWithoutRef<'div'> {
  children: React.ReactNode;
  currentStep: number;
  steps: {
    title: string;
    onClick?: () => void;
  }[];
  activeColor?: string;
}

const CheckoutProcessWrapper = ({
  children,
  currentStep,
  steps,
  activeColor,
}: IProps) => {
  const { theme } = useTheme();

  const stepperColor = activeColor || theme.colors.secondary;

  const customStyle = {
    '--active-step-color': stepperColor,
  } as React.CSSProperties;

  return (
    <div
      className="checkout-process-wrapper flex w-full flex-col gap-7 sm:w-auto"
      style={customStyle}
    >
      <BergStepper currentStep={currentStep} steps={steps} />
      {children}
    </div>
  );
};

export default CheckoutProcessWrapper;
