import React, { createContext, useContext } from 'react';
import { ClientId } from '../config/clients';
import { useWidget } from './WidgetContext';

interface ClientConfigContextType {
  config: any;
  clientId: ClientId;
}

const ClientConfigContext = createContext<ClientConfigContextType | null>(null);

export const ClientConfigProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { clientId, clientConfig } = useWidget();

  if (!clientId || !clientConfig) {
    return <div>Ładowanie konfiguracji...</div>;
  }

  return (
    <ClientConfigContext.Provider value={{ config: clientConfig, clientId }}>
      {children}
    </ClientConfigContext.Provider>
  );
};

export const useClientConfigContext = () => {
  const context = useContext(ClientConfigContext);
  if (!context) {
    throw new Error(
      'useClientConfigContext must be used within ClientConfigProvider'
    );
  }
  return context;
};
