import { Category } from '../../../types';
import button from '../../../assets/icon/oval.png';

interface ProductCardProps {
  product: Category;
  onNavigateToCategory: () => void;
  onClick?: () => void;
}

const ProductCard = ({
  product,
  onNavigateToCategory,
  onClick,
}: ProductCardProps) => (
  <div
    className="product-card h-full bg-white rounded-lg border-0 cursor-pointer overflow-hidden"
    onClick={onClick}
  >
    <img
      src={product.imageUrl}
      alt={product.name}
      className="w-full h-[20vh] object-cover"
    />
    <div className="px-4 py-2 flex flex-row justify-between">
      <div>
        <h3>{product.name}</h3>
        <p dangerouslySetInnerHTML={{ __html: product.description }}></p>
      </div>

      <button
        className="border-0 bg-transparent cursor-pointer pt-1"
        onClick={(e) => {
          e.stopPropagation();
          onNavigateToCategory();
        }}
      >
        <img src={button} />
      </button>
    </div>
  </div>
);

export default ProductCard;
