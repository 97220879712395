// Header.tsx
import React from 'react';
import { Search, ShoppingCart } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { View } from '../../types';

interface HeaderProps {
  isProductsView: boolean;
  isCartProcess: boolean;
  isMobile: boolean;
  theme: {
    colors: { primary: string; secondary: string };
    fonts: { primary: string };
  };
  widgetData?: { productCategories?: any[] };
  navigateTo: (view: View) => void;
  navigateToCategory: (product: any) => void;
  itemCount: number;
}

const Header = ({
  isProductsView,
  isCartProcess,
  isMobile,
  theme,
  widgetData,
  navigateTo,
  navigateToCategory,
  itemCount,
}: HeaderProps) => {
  const { t } = useTranslation();

  const handleProductsClick = () => {
    if (
      widgetData?.productCategories &&
      widgetData.productCategories.length === 1
    ) {
      navigateToCategory(widgetData.productCategories[0]);
    } else {
      navigateTo('products');
    }
  };

  return (
    <div className="flex-none h-full bg-[#F0F0F0] rounded-t-[10px] px-4 pt-4 pb-2">
      <div className="flex flex-row gap-2 sm:gap-5 w-full">
        <button
          className={`rounded-[90px] p-2 sm:p-4 font-bold border-0 flex items-center gap-3 w-full justify-center cursor-pointer transition-colors ${
            isProductsView
              ? 'text-white'
              : 'text-black bg-white hover:bg-gray-100'
          }`}
          style={{
            backgroundColor: isProductsView ? theme.colors.primary : '',
          }}
          onClick={handleProductsClick}
        >
          <Search color={isProductsView ? 'white' : 'black'} />{' '}
          {isMobile ? t('browse') : t('browse_offer')}
        </button>
        <button
          className={`rounded-[90px] p-2 sm:p-4 font-bold border-0 flex items-center gap-3 w-full justify-center cursor-pointer transition-colors ${
            isCartProcess
              ? 'text-white'
              : 'text-black bg-white hover:bg-gray-100'
          }`}
          style={{ backgroundColor: isCartProcess ? theme.colors.primary : '' }}
          onClick={() => navigateTo('cart')}
        >
          <ShoppingCart color={isCartProcess ? 'white' : 'black'} />
          {isMobile ? t('cart.title') : t('your_cart')}
          {itemCount > 0 && (
            <span className="bg-[#6CD370] text-white rounded-full w-6 h-6 flex items-center justify-center text-sm">
              {itemCount}
            </span>
          )}
        </button>
      </div>
    </div>
  );
};

export default Header;
