/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { useCart } from '../../../context/CartProvider';
// import { BergButton, BergCardProduct } from '@berg-regions/berg-components';
import { useTheme } from '../../../context/ThemeContext';
import './BuyerData.scss';
import { BuyerFormData, CompanyData, FormErrors } from '../../../types';
import {
  BergButton,
  BergCheckbox2,
  BergInput2,
} from '@berg-regions/berg-components';
import { useBuyerData } from '../../../context/BuyerDataContext';
import { useTranslation } from 'react-i18next';

interface BuyerDataProps {
  onContinue: () => void;
  onBack: () => void;
}

const BuyerData = ({ onContinue, onBack }: BuyerDataProps) => {
  const { t } = useTranslation();
  const { items } = useCart();
  const { theme } = useTheme();
  const { buyerData, updateBuyerData } = useBuyerData();
  const { companyData, updateCompanyData } = useBuyerData();
  const [errors, setErrors] = useState<FormErrors>({});
  const [wantsInvoice, setWantsInvoice] = useState<boolean>(false);

  const hoverColor = theme.colors.primary;
  const backgroundColor = theme.colors.secondary;

  const hoverStyle = {
    '--hover-color': hoverColor,
  } as React.CSSProperties;

  const backgroundStyle = {
    '--background-color': backgroundColor,
  } as React.CSSProperties;

  useEffect(() => {
    if (buyerData.confirmEmail && buyerData.email !== buyerData.confirmEmail) {
      setErrors((prev) => ({
        ...prev,
        confirmEmail: t('email_validation'),
      }));
    } else {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors.confirmEmail;
        return newErrors;
      });
    }
  }, [buyerData.email, buyerData.confirmEmail, t]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    updateBuyerData({ [id]: value } as Partial<BuyerFormData>);

    if (errors[id]) {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[id];
        return newErrors;
      });
    }
  };

  const formatNIP = (nip: string): string => {
    const cleanNip = nip.replace(/[^0-9]/g, '');

    if (cleanNip.length < 10) {
      return cleanNip;
    }

    const limitedNip = cleanNip.substring(0, 10);
    return `${limitedNip.substring(0, 3)}-${limitedNip.substring(
      3,
      6
    )}-${limitedNip.substring(6, 8)}-${limitedNip.substring(8, 10)}`;
  };

  const formatPostalCode = (postalCode: string): string => {
    const cleanPostalCode = postalCode.replace(/[^0-9]/g, '');

    if (cleanPostalCode.length < 5) {
      return cleanPostalCode;
    }

    const limitedPostalCode = cleanPostalCode.substring(0, 5);
    return `${limitedPostalCode.substring(0, 2)}-${limitedPostalCode.substring(
      2,
      5
    )}`;
  };

  const handleCompanyChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    if (id === 'nip') {
      const formattedNip = formatNIP(value);
      updateCompanyData({ [id]: formattedNip } as Partial<CompanyData>);
    } else if (id === 'postalCode') {
      const formattedPostalCode = formatPostalCode(value);
      updateCompanyData({ [id]: formattedPostalCode } as Partial<CompanyData>);
    } else {
      updateCompanyData({ [id]: value } as Partial<CompanyData>);
    }

    if (errors[id]) {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[id];
        return newErrors;
      });
    }
  };

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!buyerData.email.trim()) {
      newErrors.email = 'Email jest wymagany';
    } else if (!emailRegex.test(buyerData.email)) {
      newErrors.email = 'Podaj prawidłowy adres email';
    }

    if (!buyerData.confirmEmail.trim()) {
      newErrors.confirmEmail = 'Potwierdzenie email jest wymagane';
    } else if (buyerData.email !== buyerData.confirmEmail) {
      newErrors.confirmEmail = 'Adresy email nie są identyczne';
    }

    const phoneRegex = /^[0-9]{9}$/;
    if (!buyerData.phone.trim()) {
      newErrors.phone = 'Numer telefonu jest wymagany';
    } else if (!phoneRegex.test(buyerData.phone.replace(/\s/g, ''))) {
      newErrors.phone = 'Podaj prawidłowy 9-cyfrowy numer telefonu';
    }

    if (wantsInvoice) {
      const nipWithoutDashes = companyData.nip.replace(/-/g, '');
      if (!nipWithoutDashes.trim()) {
        newErrors.nip = 'NIP jest wymagany';
      } else if (
        nipWithoutDashes.length !== 10 ||
        !/^[0-9]{10}$/.test(nipWithoutDashes)
      ) {
        newErrors.nip = 'NIP musi składać się z 10 cyfr';
      } else {
        const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        const digits = nipWithoutDashes.split('').map(Number);
        let sum = 0;

        for (let i = 0; i < weights.length; i++) {
          sum += weights[i] * digits[i];
        }

        const checksum = sum % 11;
        if (checksum !== digits[9]) {
          newErrors.nip = 'NIP jest niepoprawny';
        }
      }

      if (!companyData.name || !companyData.name.trim()) {
        newErrors.nameCompany = 'Nazwa firmy jest wymagana';
      }

      if (companyData.postalCode) {
        const postalCodeWithoutDash = companyData.postalCode.replace(/-/g, '');
        if (
          postalCodeWithoutDash.length !== 5 ||
          !/^[0-9]{5}$/.test(postalCodeWithoutDash)
        ) {
          newErrors.postalCode = 'Kod pocztowy musi mieć format XX-XXX';
        }
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChangeShowCompanyData = () => {
    setWantsInvoice((value) => !value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      if (window.parent && window.parent !== window) {
        try {
          window.parent.postMessage(
            {
              type: 'BUYER_DATA_SUBMITTED',
              data: {
                buyerData,
                companyData: wantsInvoice ? companyData : null,
                wantsInvoice,
              },
            },
            '*'
          );
        } catch (error) {
          console.error(
            'Nie udało się wysłać danych do nadrzędnej aplikacji',
            error
          );
        }
      }

      onContinue();
    }
  };

  return (
    <div className="buyer-data flex flex-col md:flex-row gap-6 mb-[65%] sm:mb-0">
      <div className="w-full md:w-2/3 bg-white rounded-lg p-4 shadow-sm">
        <h2 className="text-xl font-semibold mb-4">{t('buyer_data.title')}</h2>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-1 gap-2">
            <BergInput2
              id="firstName"
              label={t('buyer_data.name')}
              onChange={handleChange}
              value={buyerData.firstName}
              required
              maxLength={256}
              error={errors.firstName || undefined}
            />
            <BergInput2
              id="lastName"
              label={t('buyer_data.surname')}
              onChange={handleChange}
              value={buyerData.lastName}
              required
              maxLength={256}
              error={errors.lastName || undefined}
            />
            <BergInput2
              id="email"
              label={t('buyer_data.email')}
              onChange={handleChange}
              value={buyerData.email}
              type="email"
              required
              maxLength={50}
              error={errors.email || undefined}
            />
            <BergInput2
              id="confirmEmail"
              label={t('buyer_data.repeat_email')}
              onChange={handleChange}
              value={buyerData.confirmEmail}
              type="email"
              required
              maxLength={50}
              error={errors.confirmEmail || undefined}
            />
            <BergInput2
              id="phone"
              label={t('buyer_data.phone')}
              onChange={handleChange}
              value={buyerData.phone}
              type="tel"
              required
              maxLength={15}
              error={errors.phone || undefined}
            />
            <div className="flex w-full flex-col justify-start sm:w-[573px] sm:flex-row sm:justify-evenly">
              <label role="placeholder" className="hidden sm:block" />
              <BergCheckbox2
                type="checkbox"
                selected={wantsInvoice}
                onValueChange={handleChangeShowCompanyData}
                name={'gg'}
                label={t('buyer_data.want_to_receive')}
              />
            </div>

            {wantsInvoice && (
              <div className="sm:auto flex w-full flex-col gap-3">
                <BergInput2
                  id="nip"
                  label={t('buyer_data.tax_id')}
                  onChange={handleCompanyChange}
                  value={companyData.nip}
                  required={wantsInvoice}
                  maxLength={13}
                  error={errors.nip || undefined}
                  placeholder="XXX-XXX-XX-XX"
                />
                <BergInput2
                  id="name"
                  label={t('buyer_data.company_name')}
                  onChange={handleCompanyChange}
                  value={companyData.name}
                  required={wantsInvoice}
                  maxLength={256}
                  error={errors.name || undefined}
                />
                <BergInput2
                  id="city"
                  label={t('buyer_data.city')}
                  onChange={handleCompanyChange}
                  required={wantsInvoice}
                  value={companyData.city}
                  maxLength={256}
                  error={errors.city || undefined}
                />
                <BergInput2
                  id="street"
                  label={t('buyer_data.street')}
                  onChange={handleCompanyChange}
                  required={wantsInvoice}
                  value={companyData.street}
                  maxLength={256}
                  error={errors.street || undefined}
                />
                <BergInput2
                  id="houseNumber"
                  label={t('buyer_data.house_number')}
                  onChange={handleCompanyChange}
                  required={wantsInvoice}
                  value={companyData.houseNumber}
                  maxLength={256}
                  error={errors.houseNumber || undefined}
                />
                <BergInput2
                  id="postalCode"
                  label={t('buyer_data.postal_code')}
                  onChange={handleCompanyChange}
                  required={wantsInvoice}
                  value={companyData.postalCode}
                  maxLength={6}
                  error={errors.postalCode || undefined}
                  placeholder="XX-XXX"
                />
              </div>
            )}
          </div>

          <div className="flex flex-col sm:flex-row gap-3 mt-6">
            <BergButton
              bg="transparent"
              border="black"
              color="black"
              animation={true}
              onClick={onBack}
              className="cursor-pointer"
            >
              {t('back_to_cart')}
            </BergButton>
            <span style={{ ...hoverStyle, ...backgroundStyle }}>
              <BergButton
                bg="secondary"
                border="white"
                type="submit"
                animation={true}
                disabled={items.length === 0}
                className="cursor-pointer"
              >
                {t('go_on')}
              </BergButton>
            </span>
          </div>
        </form>
      </div>

      {/* <div className="w-full md:w-1/3">
        <div className="bg-white rounded-lg p-4 shadow-sm">
          <h3 className="text-lg font-semibold mb-3">
            Podsumowanie zamówienia
          </h3>

          <div className="max-h-64 overflow-y-auto mb-3">
            {items.map((item) => (
              <div
                key={item.id}
                className="py-2 border-b border-gray-100 last:border-b-0"
              >
                <div className="flex justify-between gap-2">
                  <span className="font-medium w-2/3">{item.name}</span>
                  <span>
                    {item.quantity} x {item.price} zł
                  </span>
                </div>
                <div className="text-sm text-gray-500">
                  {item.date && <p>Data: {item.date}</p>}
                  {item.hour && <p>Godzina: {item.hour}</p>}
                  {item.type === 'ticket' && item.ticketType && (
                    <p>Typ biletu: {item.ticketType}</p>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="flex justify-between font-bold text-lg pt-2 border-t border-gray-200">
            <span>Razem:</span>
            <span>{total.toFixed(2)} zł</span>
          </div>

          <p className="text-sm text-gray-500 mt-3">Obsługa {theme.client}</p>
        </div>
      </div> */}
    </div>
  );
};

export default BuyerData;
