// import React from 'react';

export type View =
  | 'products'
  | 'category'
  | 'cart'
  | 'buyer-data'
  | 'summary'
  | 'payment'
  | 'completion';

export interface HourlyAvailability {
  startTime: string;
  endTime: string;
  maxCapacity: number;
  soldSeats: number;
}

export interface DailyAvailability {
  date: string;
  type: 'WHOLE_DAY' | 'TIME_SLOT';
  maxCapacity: number;
  unlimited: boolean;
  soldSeats: number;
  hourlyAvailability: HourlyAvailability[] | null;
}

export type Availability = DailyAvailability[];

export interface BuyerFormData {
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  phone: string;
  acceptTerms: boolean;
}

export interface CompanyData {
  nip: string;
  name: string;
  city: string;
  street: string;
  houseNumber: string;
  postalCode: string;
}

export interface FormErrors {
  [key: string]: string;
}

export interface BaseCartItem extends AddToCartItem {
  quantity: number;
  date: string;
  hour: string;
  type: widgetProductType;
}

export interface ParkingCartItem extends BaseCartItem {
  type: widgetProductType.PARKING_TICKETS;
  registrationNumbers: string[];
}
export interface TicketCartItem extends BaseCartItem {
  type:
    | widgetProductType.ENTRY_TICKETS
    | widgetProductType.ACCOMMODATION
    | widgetProductType.OTHER_SERVICES;
  ticketType?: 'normal' | 'reduced' | 'group';
}

export enum widgetProductType {
  ENTRY_TICKETS = 'ENTRY_TICKETS',
  ACCOMMODATION = 'ACCOMMODATION',
  PARKING_TICKETS = 'PARKING_TICKETS',
  OTHER_SERVICES = 'OTHER_SERVICES',
}

export type CartItem = ParkingCartItem | TicketCartItem;

export interface AddToCartItem {
  id: string;
  name: string;
  price: number;
  quantity: number;
  date: string;
  hour: string;
  type: widgetProductType;
  registrationNumbers?: string[];
  ticketType?: 'normal' | 'reduced' | 'group';
  description?: string;
  fullDescription?: string;
  coverImageUrl?: string;
  consumerCategories?: ConsumerCategories[];
  requirePersonalData?: true;
}

interface Price {
  netAmount: string;
  grossAmount: string;
  currency: string;
  tax: {
    name: string;
    value: string;
  };
}
export interface ConsumerCategories {
  id: string;
  name: string;
  description: string;
  price: Price;
  priceBeforeRecalculation: null;
  lowestPriceIn30Days: Price;
  priceRecalculated: false;
  isSelected: boolean;
}

export interface ProductItem {
  id: string;
  productId: number;
  name: string;
  description: string;
  fullDescription: string;
  coverImageUrl: string;
  consumerCategories: ConsumerCategories[];
  requirePersonalData: true;
}

export interface Category {
  id: number;
  name: string;
  description: string;
  imageUrl: string;
  widgetProductType: widgetProductType;
}

export interface WidgetData {
  name: string;
  termsUrl: string;
  supportUrl: string;
  defaultLanguage: string;
  supportedLanguages: string[];
  productCategories: Category[];
}
