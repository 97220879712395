// import React, { createContext, useState, useContext, useEffect } from 'react';
// import { useWidgetId } from '../hooks/useWidgetId';
// import i18n from './i18n';
// import { WidgetApi } from '../services/apiService';
// import { WidgetData } from '../types';

// interface WidgetContextType {
//   widgetData: WidgetData | null;
//   isLoading: boolean;
//   error: string | null;
//   refreshWidgetData: () => Promise<void>;
//   widgetId: string | null;
// }

// const WidgetContext = createContext<WidgetContextType | undefined>(undefined);

// export const WidgetProvider: React.FC<{ children: React.ReactNode }> = ({
//   children,
// }) => {
//   const [widgetData, setWidgetData] = useState<WidgetData | null>(null);
//   const [isLoading, setIsLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);
//   const { widgetId } = useWidgetId();

//   const loadWidgetData = async () => {
//     if (!widgetId) {
//       setIsLoading(false);
//       setError('Brak identyfikatora widgetu w URL');
//       return;
//     }

//     setIsLoading(true);
//     setError(null);

//     try {
//       const data = await WidgetApi.getWidgetData(widgetId);
//       setWidgetData(data);

//       if (
//         data.defaultLanguage &&
//         data.supportedLanguages.includes(data.defaultLanguage)
//       ) {
//         const browserLang = navigator.language.split('-')[0];

//         if (data.supportedLanguages.includes(browserLang)) {
//           i18n.changeLanguage(browserLang);
//         } else {
//           i18n.changeLanguage(data.defaultLanguage);
//         }
//       }
//     } catch (err) {
//       console.error('Błąd podczas ładowania danych widgetu:', err);
//       setError('Nie udało się załadować danych widgetu');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (widgetId) {
//       loadWidgetData();
//     }
//   }, [widgetId]);

//   const refreshWidgetData = async () => {
//     await loadWidgetData();
//   };

//   const value = {
//     widgetData,
//     isLoading,
//     error,
//     refreshWidgetData,
//     widgetId,
//   };

//   return (
//     <WidgetContext.Provider value={value}>{children}</WidgetContext.Provider>
//   );
// };

// export const useWidget = (): WidgetContextType => {
//   const context = useContext(WidgetContext);
//   if (context === undefined) {
//     throw new Error('useWidget must be used within a WidgetProvider');
//   }
//   return context;
// };

import React, { createContext, useState, useContext, useEffect } from 'react';
import { useWidgetId } from '../hooks/useWidgetId';
import i18n from './i18n';
import { WidgetApi } from '../services/apiService';
import { WidgetData } from '../types';
import { getClientConfigFromWidgetId } from '../config/widgetMapping';
import { ClientId, clients } from '../config/clients';

interface WidgetContextType {
  widgetData: WidgetData | null;
  isLoading: boolean;
  error: string | null;
  refreshWidgetData: () => Promise<void>;
  widgetId: string | null;
  clientId: ClientId | null;
  clientConfig: (typeof clients)[ClientId] | null;
}

const WidgetContext = createContext<WidgetContextType | undefined>(undefined);

export const WidgetProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [widgetData, setWidgetData] = useState<WidgetData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { widgetId } = useWidgetId();
  const [clientId, setClientId] = useState<ClientId | null>(null);
  const [clientConfig, setClientConfig] = useState<
    (typeof clients)[ClientId] | null
  >(null);

  useEffect(() => {
    if (widgetId) {
      const clientData = getClientConfigFromWidgetId(widgetId);

      if (clientData) {
        setClientId(clientData.clientId);
        setClientConfig(clientData.config);
      } else {
        setClientId(null);
        setClientConfig(null);
        console.error(
          `Nie znaleziono konfiguracji klienta dla widgetId: ${widgetId}`
        );
      }
    }
  }, [widgetId]);

  const loadWidgetData = async () => {
    if (!widgetId) {
      setIsLoading(false);
      setError('Brak identyfikatora widgetu w URL');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const data = await WidgetApi.getWidgetData(widgetId);
      setWidgetData(data);

      if (
        data.defaultLanguage &&
        data.supportedLanguages.includes(data.defaultLanguage)
      ) {
        const browserLang = navigator.language.split('-')[0];

        if (data.supportedLanguages.includes(browserLang)) {
          i18n.changeLanguage(browserLang);
        } else {
          i18n.changeLanguage(data.defaultLanguage);
        }
      }
    } catch (err) {
      console.error('Błąd podczas ładowania danych widgetu:', err);

      // Używamy domyślnych danych z clientConfig, gdy API zwraca błąd
      if (clientConfig) {
        console.log('Używanie danych konfiguracyjnych klienta jako fallback');
        // Tutaj możesz utworzyć obiekt widgetData z danymi clientConfig
        // Przykład:
        // const fallbackData: WidgetData = { ... };
        // setWidgetData(fallbackData);
      } else {
        setError('Nie udało się załadować danych widgetu');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (widgetId) {
      loadWidgetData();
    }
  }, [widgetId]);

  const refreshWidgetData = async () => {
    await loadWidgetData();
  };

  const value = {
    widgetData,
    isLoading,
    error,
    refreshWidgetData,
    widgetId,
    clientId,
    clientConfig,
  };

  return (
    <WidgetContext.Provider value={value}>{children}</WidgetContext.Provider>
  );
};

export const useWidget = (): WidgetContextType => {
  const context = useContext(WidgetContext);
  if (context === undefined) {
    throw new Error('useWidget must be used within a WidgetProvider');
  }
  return context;
};
