/* eslint-disable complexity */
import { useEffect, useState } from 'react';
import { useTheme } from '../../context/ThemeContext';
import { WidgetData, View, Category } from '../../types';
import ProductGrid from '../views/ProductGrid/ProductGrid';
import CategoryView from '../views/CategoryView/CategoryView';
import Cart from '../views/Cart/Cart';
import { useCart } from '../../context/CartProvider';
import useCartSteps from '../../hooks/useCartSteps';
import CheckoutProcessWrapper from '../CheckoutProcessWrapper/CheckoutProcessWrapper';
import { LifeBuoy } from 'lucide-react';
import BuyerData from '../views/BuyerData/BuyerData';
import Summary from '../views/Summary/Summary';
import { BergIcon } from '@berg-regions/berg-components';
import LanguageSelection from '../LanguageSelection/LanguageSelection';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../services/axios';
import { useI18n } from '../../context/i18nContext';
import useWidgetId from '../../hooks/useWidgetId';
import Header from './Header';

const WidgetLayout = () => {
  const [currentView, setCurrentView] = useState<View>('products');
  const [selectedProduct, setSelectedProduct] = useState<Category>();
  const [currentStep, setCurrentStep] = useState(0);
  const { theme } = useTheme();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const { itemCount } = useCart();
  const { t } = useTranslation();
  const [widgetData, setWidgetData] = useState<WidgetData>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { setSupportedLanguages, setDefaultLanguage } = useI18n();
  const { widgetId } = useWidgetId();
  const AUTO_NAVIGATE_SINGLE_CATEGORY = true;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navigateTo = (view: View) => {
    setCurrentView(view);

    if (view === 'cart') setCurrentStep(0);
    else if (view === 'buyer-data') setCurrentStep(1);
    else if (view === 'summary') setCurrentStep(2);
    else if (view === 'payment') setCurrentStep(3);
    else if (view === 'completion') setCurrentStep(4);
  };

  const navigateToCategory = (product: Category) => {
    setSelectedProduct(product);
    setCurrentView('category');
  };

  const { steps } = useCartSteps({
    currentStep,
    navigateTo,
  });

  const isCartProcess = [
    'cart',
    'buyer-data',
    'summary',
    'payment',
    'completion',
  ].includes(currentView);

  const isProductsView = ['products', 'category'].includes(currentView);

  useEffect(() => {
    const fetchWidgetData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const id = widgetId || '1';
        const response = await axiosInstance.get(`/v2/external/widget/${id}`);
        setWidgetData(response.data);

        if (response.data.supportedLanguages) {
          setSupportedLanguages(response.data.supportedLanguages);
        }

        if (response.data.defaultLanguage) {
          setDefaultLanguage(response.data.defaultLanguage);
        }
      } catch (error) {
        console.error('Błąd podczas pobierania danych widgetu:', error);
        setError('Nie udało się pobrać danych widgetu');
      } finally {
        setIsLoading(false);
      }
    };

    fetchWidgetData();
  }, [widgetId, setSupportedLanguages, setDefaultLanguage]);

  useEffect(() => {
    // Jeżeli widgetData jest już pobrane, flaga jest true i jest tylko jedna kategoria,
    // przejdź do widoku "category"
    if (
      AUTO_NAVIGATE_SINGLE_CATEGORY &&
      widgetData &&
      widgetData.productCategories &&
      widgetData.productCategories.length === 1
    ) {
      navigateToCategory(widgetData.productCategories[0]);
    }
  }, [widgetData]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="flex justify-center items-center h-full">
          <span className="text-lg">{t('loading')}</span>
        </div>
      );
    }

    if (error) {
      return (
        <div className="flex justify-center items-center h-full text-red-500">
          <span className="text-lg">{error}</span>
        </div>
      );
    }

    let content;

    switch (currentView) {
      case 'products':
        content = (
          <ProductGrid
            onNavigateToCategory={navigateToCategory}
            productCategories={widgetData?.productCategories}
          />
        );
        break;
      case 'category':
        content = (
          <CategoryView
            selectedProduct={selectedProduct}
            onNavigateToCart={() => navigateTo('cart')}
          />
        );
        break;
      case 'cart':
        content = <Cart onProceedToCheckout={() => navigateTo('buyer-data')} />;
        break;
      case 'buyer-data':
        content = (
          <BuyerData
            onBack={() => navigateTo('cart')}
            onContinue={() => navigateTo('summary')}
          />
        );
        break;
      case 'summary':
        content = (
          <Summary
            onBack={() => navigateTo('buyer-data')}
            onContinue={() => navigateTo('payment')}
          />
        );
        break;
      // case 'payment':
      //   content = (
      //     <Payment
      //       onBack={() => navigateTo('summary')}
      //       onComplete={() => navigateTo('completion')}
      //     />
      //   );
      //   break;
      // case 'completion':
      //   content = (
      //     <Completion
      //       onGoHome={() => navigateTo('products')}
      //     />
      //   );
      //   break;
      default:
        content = (
          <ProductGrid
            onNavigateToCategory={navigateToCategory}
            productCategories={widgetData?.productCategories}
          />
        );
    }

    if (isCartProcess) {
      return (
        <CheckoutProcessWrapper
          currentStep={currentStep}
          steps={steps}
          activeColor={theme.colors.secondary}
        >
          {content}
        </CheckoutProcessWrapper>
      );
    }

    return content;
  };

  return (
    <div
      className="widget-container h-screen max-h-[800px] flex flex-col"
      style={{ fontFamily: theme.fonts.primary }}
    >
      {widgetData && <h1 className="pb-6">{widgetData.name}</h1>}
      {/* Stały nagłówek */}
      <Header
        isProductsView={isProductsView}
        isCartProcess={isCartProcess}
        isMobile={isMobile}
        theme={theme}
        widgetData={widgetData}
        navigateTo={navigateTo}
        navigateToCategory={navigateToCategory}
        itemCount={itemCount}
      />

      <div className="flex-1 overflow-y-auto">
        <main className="h-full bg-[#F0F0F0] rounded-b-[10px] px-2 sm:p-3">
          {renderContent()}
          <div
            className={`footer flex justify-between ${
              currentView === 'cart'
                ? '-mt-[15rem] sm:mt-[auto]'
                : '-mt-[15rem] sm:mt-1'
            } text-[#808084] text-[12px]`}
          >
            <div className="flex gap-8 items-center">
              <a
                href={widgetData?.supportUrl}
                className="flex items-center gap-2 text-[#808084] no-underline cursor-pointer"
              >
                <LifeBuoy />
                {t('contact_support')}
              </a>

              <div>
                <LanguageSelection
                  supportedLanguages={widgetData?.supportedLanguages}
                />
              </div>
            </div>

            <div className="flex items-center gap-2">
              <a
                href="https://bergregions.pl/"
                className="flex items-center gap-2 text-[#808084] no-underline cursor-pointer"
              >
                Powered by <BergIcon name="powered_by" />
              </a>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
export default WidgetLayout;
