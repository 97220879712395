import { useState, useEffect } from 'react';

export const useWidgetId = () => {
  const [widgetId, setWidgetId] = useState<string | null>(null);

  useEffect(() => {
    const path = window.location.pathname;

    const cleanPath = path.startsWith('/') ? path.substring(1) : path;
    const firstSegment = cleanPath.split('/')[0];

    if (firstSegment) {
      setWidgetId(firstSegment);
    } else {
      setWidgetId(null);
      console.error('Brak widgetId w URL');
    }
  }, []);

  return { widgetId };
};

export default useWidgetId;
