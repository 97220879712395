/* eslint-disable complexity */
import { ConsumerCategories, ProductItem } from '../types';

export const getCurrencySymbol = (currencyCode: string): string => {
  switch (currencyCode.toUpperCase()) {
    case 'PLN':
      return 'zł';
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
    case 'GBP':
      return '£';
    case 'CHF':
      return 'CHF';
    case 'CZK':
      return 'Kč';
    case 'HUF':
      return 'Ft';
    case 'NOK':
      return 'kr';
    case 'SEK':
      return 'kr';
    case 'DKK':
      return 'kr';
    case 'RON':
      return 'lei';
    case 'BGN':
      return 'лв';
    case 'HRK':
      return 'kn';
    case 'UAH':
      return '₴';
    case 'RUB':
      return '₽';
    default:
      return currencyCode;
  }
};

// Funkcja do pobierania symbolu waluty z pojedynczej kategorii konsumenckiej
export const getCurrency = (item: ConsumerCategories): string => {
  if (!item.price) {
    return 'zł';
  }

  const currencyCode = item.price.currency;
  return getCurrencySymbol(currencyCode);
};

// Funkcja do pobierania najniższej ceny z kategorii konsumenckich
export const getLowestPrice = (product: ProductItem): string => {
  if (!product.consumerCategories || product.consumerCategories.length === 0) {
    return '0';
  }

  const lowestPrice = product.consumerCategories.reduce((min, category) => {
    const price = parseFloat(category.price.grossAmount);
    return price < min ? price : min;
  }, parseFloat(product.consumerCategories[0].price.grossAmount));

  return `${lowestPrice.toString()} ${getCurrency(
    product.consumerCategories[0]
  )}`;
};

// Funkcja pomocnicza do znalezienia kategorii z najniższą ceną
export const getCategoryWithLowestPrice = (
  product: ProductItem
): ConsumerCategories | null => {
  if (!product.consumerCategories || product.consumerCategories.length === 0) {
    return null;
  }

  return product.consumerCategories.reduce((minCategory, category) => {
    const currentPrice = parseFloat(category.price.grossAmount);
    const minPrice = parseFloat(minCategory.price.grossAmount);
    return currentPrice < minPrice ? category : minCategory;
  }, product.consumerCategories[0]);
};

// Funkcja do pobierania najniższej ceny z ostatnich 30 dni dla kategorii z najniższą ceną
export const getLowestPriceIn30Days = (product: ProductItem): string => {
  const lowestPriceCategory = getCategoryWithLowestPrice(product);
  if (!lowestPriceCategory) {
    return '0';
  }

  return lowestPriceCategory.lowestPriceIn30Days.grossAmount;
};

// Funkcja do formatowania ceny z symbolem waluty dla danej kategorii
export const formatPriceForCategory = (category: ConsumerCategories): string =>
  `${category.price.grossAmount} ${getCurrency(category)}`;

// Funkcja do formatowania ceny najniższej z odpowiadającą jej walutą
export const formatLowestPrice = (product: ProductItem): string => {
  const lowestPriceCategory = getCategoryWithLowestPrice(product);
  if (!lowestPriceCategory) {
    return '0 zł';
  }

  return formatPriceForCategory(lowestPriceCategory);
};
