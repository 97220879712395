import {
  AddToCartItem,
  Category,
  ConsumerCategories,
  ProductItem,
  widgetProductType,
} from '../../../types';
import { useCart } from '../../../context/CartProvider';
import './CategoryView.scss';
import { BergOfferCard } from '@berg-regions/berg-components';
import React, { useEffect, useRef, useState } from 'react';
// import ParkingFormModal from '../ProductFormModal/ParkingFormModal';
import TicketFormModal from '../ProductFormModal/TicketFormModal';
import { useTranslation } from 'react-i18next';
import { ProductApi } from '../../../services/apiService';
import { adaptDescription } from './descriptionAdapter';
import {
  formatLowestPrice,
  getLowestPrice,
} from '../../../hooks/currencyUtils';

interface CategoryViewProps {
  selectedProduct: Category | undefined;
  onNavigateToCart: () => void;
}

interface TicketFormData {
  tickets: {
    [key: string]: ConsumerCategories & {
      quantity: number;
      isSelected: boolean;
    };
  };
  date: string | null;
  time: string;
}

const CategoryView = ({ selectedProduct }: CategoryViewProps) => {
  const { t } = useTranslation();
  const { addToCart } = useCart();
  const [product, setProduct] = useState<ProductItem[] | null>(null);
  const [selectedItem, setSelectedItem] = useState<ProductItem | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const categoryViewRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCategoryData = async () => {
      if (!selectedProduct) return;

      setIsLoading(true);
      setError(null);

      try {
        const widgetId = 1; // Tutaj można użyć widgetId z kontekstu, jeśli jest dostępny
        const data = await ProductApi.getProductsByCategory(
          widgetId,
          selectedProduct.id
        );
        setProduct(data);
      } catch (error) {
        console.error('Błąd podczas pobierania produktów:', error);
        setError('Nie udało się pobrać produktów dla tej kategorii');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategoryData();
  }, [selectedProduct]);

  useEffect(() => {
    const widgetContent = document.querySelector(
      '.widget-content'
    ) as HTMLElement;

    if (isModalOpen) {
      if (widgetContent) {
        setScrollPosition(widgetContent.scrollTop);
        widgetContent.style.overflow = 'hidden';
      }

      document.body.classList.add('modal-open');
    } else {
      if (widgetContent) {
        widgetContent.style.overflow = 'auto';
        setTimeout(() => {
          if (widgetContent) {
            widgetContent.scrollTop = scrollPosition;
          }
        }, 10);
      }

      document.body.classList.remove('modal-open');
    }

    return () => {
      if (widgetContent) {
        widgetContent.style.overflow = 'auto';
      }
      document.body.classList.remove('modal-open');
    };
  }, [isModalOpen, scrollPosition]);

  if (!selectedProduct) return null;

  const handleBookOfferCard = (item: ProductItem) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  // const handleFormParkingSubmit = (formData: {
  //   quantity: number;
  //   registrationNumbers: string[];
  //   date: string;
  //   hour: string;
  // }) => {
  //   if (selectedItem) {
  //     const itemWithDetails: AddToCartItem = {
  //       ...selectedItem,
  //       type: widgetProductType.PARKING_TICKETS,
  //       quantity: formData.quantity,
  //       registrationNumbers: formData.registrationNumbers,
  //       date: formData.date,
  //       hour: formData.hour,
  //     };
  //     addToCart(itemWithDetails);
  //   }
  // };

  // console.log(handleFormParkingSubmit);

  const handleFormTicketSubmit = (formData: TicketFormData) => {
    if (selectedItem) {
      const selectedTickets = Object.values(formData.tickets).filter(
        (ticket) => ticket.isSelected
      );

      selectedTickets.forEach((ticket) => {
        const itemWithDetails: AddToCartItem = {
          id: `${selectedItem.id}-${ticket.id}`,
          name: `${selectedItem.name} - ${ticket.name}`,
          price: parseFloat(ticket.price.grossAmount),
          quantity: ticket.quantity,
          date: formData.date || '',
          hour: formData.time,
          type: selectedProduct.widgetProductType,
          ticketType: ticket.id as 'normal' | 'reduced' | 'group',
          description: selectedItem.description,
          fullDescription: selectedItem.fullDescription,
          coverImageUrl: selectedItem.coverImageUrl,
          consumerCategories: [ticket],
          requirePersonalData: selectedItem.requirePersonalData,
        };

        addToCart(itemWithDetails);
      });
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full p-4">
        <span className="text-lg">{t('loading')}</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-full p-4 text-red-500">
        <span className="text-lg">{error}</span>
      </div>
    );
  }

  if (!product || product.length === 0) {
    return (
      <div className="flex justify-center items-center h-full p-4">
        <span className="text-lg">{t('no_products')}</span>
      </div>
    );
  }

  return (
    <div className={`w-full h-full relative `}>
      <div
        className={`category-view flex flex-col gap-2 `}
        ref={categoryViewRef}
      >
        {product?.map((item) => (
          <BergOfferCard
            key={item.id}
            title={item.name}
            id={item.id}
            handleBookOfferCard={() => handleBookOfferCard(item)}
            labelButton={t('category.buy_now')}
            labelPrice={t('category.since')}
            price={getLowestPrice(item)}
            lowestPriceInformation={`${t(
              'ticket_modal.desc_price'
            )}${formatLowestPrice(item)}`}
            sale={
              item.consumerCategories[0]?.priceRecalculated
                ? '24'
                : t('ticket_modal.sale')
            }
            imagesArr={
              item.coverImageUrl
                ? [
                    {
                      url: item.coverImageUrl,
                      id: Number(item.id),
                      thumbnailUrl: item.coverImageUrl,
                    },
                  ]
                : []
            }
            description={adaptDescription(item.description)}
          />
        ))}
      </div>
      {/* {selectedItem && product.type === 'parking' && (
        <ParkingFormModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleFormParkingSubmit}
          product={selectedItem}
        />
      )} */}
      {selectedItem &&
        (selectedProduct.widgetProductType ===
          widgetProductType.ENTRY_TICKETS ||
          selectedProduct.widgetProductType ===
            widgetProductType.OTHER_SERVICES) && (
          <TicketFormModal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            onSubmit={handleFormTicketSubmit}
            product={selectedItem}
            category={selectedProduct}
          />
        )}
    </div>
  );
};
export default CategoryView;
