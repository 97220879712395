import React from 'react';
import { useCart } from '../../../context/CartProvider';
import { BergButton, BergCardProduct } from '@berg-regions/berg-components';
import { useTheme } from '../../../context/ThemeContext';
import './Cart.scss';
import { useTranslation } from 'react-i18next';
import { widgetProductType } from '../../../types';

interface CartProps {
  onProceedToCheckout: () => void;
}

const Cart = ({ onProceedToCheckout }: CartProps) => {
  const { t } = useTranslation();
  const { items, removeFromCart, clearCart } = useCart();
  const theme = useTheme();

  const hoverColor = theme.theme.colors.primary;
  const backgroundColor = theme.theme.colors.secondary;

  const hoverStyle = {
    '--hover-color': hoverColor,
  } as React.CSSProperties;

  const backgroundStyle = {
    '--background-color': backgroundColor,
  } as React.CSSProperties;

  const total = items.reduce((sum, item) => sum + 1 * item.quantity, 0);

  if (items.length === 0) {
    return (
      <div className="bg-[#F0F0F0CC] flex flex-col items-center justify-center h-full p-4">
        <h2 className="text-xl font-semibold mb-2">
          {t('cart.your_cart_is_empty')}
        </h2>
        <p className="text-gray-500">{t('cart.add_products')}</p>
      </div>
    );
  }

  const handleName = (type: widgetProductType | undefined) => {
    if (type === widgetProductType.ENTRY_TICKETS) {
      return t('cart.your_products');
    }
    if (type === widgetProductType.ACCOMMODATION) {
      return t('cart.your_accommodations');
    }
    return t('cart.your_products');
  };

  return (
    <div className="cart flex flex-col h-full sm:mb-0 mb-[65%]">
      <div className="flex-1 overflow-y-auto">
        <h1 className="mb-2 text-[24px]">
          {handleName(items[0].type) || 'ticket'}
        </h1>
        {items.map((item) => (
          <div key={item.id} className="mb-2">
            <BergCardProduct
              productName={item.name}
              productDate={item.date}
              typesNumberOfDay={item.hour}
              productTotalPrice={`${1}zl`}
              labelAdult={t('cart.count_tickets')}
              numberOfAdults={item.quantity}
              isTrash={true}
              handleDelete={() => removeFromCart(item.id)}
            />
          </div>
        ))}
      </div>

      <div className="flex flex-col gap-5 self-stretch sm:self-end sm:mt-4">
        <div>
          <div className="flex flex-col items-end justify-end gap-0 sm:flex-row sm:gap-2">
            <p className="text-[22px]">{t('cart.order_value')}</p>
            <strong className="text-[32px] leading-[34px]">
              {total.toFixed(2)} zł
            </strong>
          </div>
          <p className="text-end leading-[22px] text-[#23264199]">
            {t('cart.tax')}
          </p>
        </div>
        <div className="cart-button w-full flex flex-col-reverse gap-5 sm:flex-row sm:self-end self-center">
          <BergButton
            bg="transparent"
            border="black"
            color="black"
            animation={true}
            onClick={clearCart}
            className="cursor-pointer w-2/3 sm:w-full justify-self-center"
          >
            {t('cart.clear_basket')}
          </BergButton>
          <span style={{ ...hoverStyle, ...backgroundStyle }}>
            <BergButton
              bg="secondary"
              border="white"
              animation={true}
              disabled={items.length === 0}
              onClick={onProceedToCheckout}
              className="cursor-pointer "
            >
              {t('go_on')}
            </BergButton>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Cart;
