import { useMemo } from 'react';
import { useCart } from '../context/CartProvider';

import { View } from '../types';
import { useTranslation } from 'react-i18next';

interface UseCartStepsProps {
  currentStep: number;
  navigateTo: (view: View) => void;
}

const useCartSteps = ({ currentStep, navigateTo }: UseCartStepsProps) => {
  const { t } = useTranslation();
  const { itemCount } = useCart();

  const isBasketNotEmpty = itemCount > 0;

  const steps = useMemo(
    () => [
      {
        title: t('cart_step.step1'),
        onClick:
          currentStep !== 0
            ? () => {
                navigateTo('cart');
              }
            : undefined,
      },
      {
        title: t('cart_step.step2'),
        onClick:
          currentStep !== 1 && isBasketNotEmpty
            ? () => {
                navigateTo('buyer-data');
              }
            : undefined,
      },
      {
        title: t('cart_step.step3'),
        onClick:
          currentStep !== 2 && isBasketNotEmpty
            ? () => {
                navigateTo('summary');
              }
            : undefined,
      },
      {
        title: t('cart_step.step4'),
        onClick: undefined,
      },
      {
        title: t('cart_step.step5'),
        onClick: undefined,
      },
    ],
    [currentStep, isBasketNotEmpty, navigateTo, t]
  );

  return { steps, currentStep };
};

export default useCartSteps;
