import React, { createContext, useContext, useState, useEffect } from 'react';
import { BuyerFormData, CompanyData } from '../types';

export const initialBuyerData: BuyerFormData = {
  firstName: '',
  lastName: '',
  email: '',
  confirmEmail: '',
  phone: '',
  acceptTerms: false,
};

export const initialCompanyData: CompanyData = {
  name: '',
  nip: '',
  city: '',
  street: '',
  houseNumber: '',
  postalCode: '',
};

interface BuyerDataContextType {
  buyerData: BuyerFormData;
  companyData: typeof initialCompanyData;
  updateBuyerData: (data: Partial<BuyerFormData>) => void;
  updateCompanyData: (data: Partial<typeof initialCompanyData>) => void;
  resetBuyerData: () => void;
  resetCompanyData: () => void;
}

const BuyerDataContext = createContext<BuyerDataContextType | undefined>(
  undefined
);

export const BuyerDataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [buyerData, setBuyerData] = useState<BuyerFormData>(() => {
    const savedData = localStorage.getItem('buyerData');
    return savedData ? JSON.parse(savedData) : initialBuyerData;
  });

  const [companyData, setCompanyData] = useState(() => {
    const savedCompanyData = localStorage.getItem('companyData');
    return savedCompanyData ? JSON.parse(savedCompanyData) : initialCompanyData;
  });

  useEffect(() => {
    localStorage.setItem('buyerData', JSON.stringify(buyerData));
  }, [buyerData]);

  useEffect(() => {
    localStorage.setItem('companyData', JSON.stringify(companyData));
  }, [companyData]);

  const updateBuyerData = (data: Partial<BuyerFormData>) => {
    setBuyerData((prevData) => ({
      ...prevData,
      ...data,
    }));
  };

  const updateCompanyData = (data: Partial<typeof initialCompanyData>) => {
    setCompanyData((prevData: any) => ({
      ...prevData,
      ...data,
    }));
  };

  const resetBuyerData = () => {
    setBuyerData(initialBuyerData);
    localStorage.removeItem('buyerData');
  };

  const resetCompanyData = () => {
    setCompanyData(initialCompanyData);
    localStorage.removeItem('companyData');
  };

  return (
    <BuyerDataContext.Provider
      value={{
        buyerData,
        companyData,
        updateBuyerData,
        updateCompanyData,
        resetBuyerData,
        resetCompanyData,
      }}
    >
      {children}
    </BuyerDataContext.Provider>
  );
};

export const useBuyerData = () => {
  const context = useContext(BuyerDataContext);
  if (!context) {
    throw new Error('useBuyerData must be used within a BuyerDataProvider');
  }
  return context;
};
