import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  AddToCartItem,
  CartItem,
  ParkingCartItem,
  TicketCartItem,
  widgetProductType,
} from '../types';

interface CartContextType {
  items: CartItem[];
  addToCart: (product: AddToCartItem) => void;
  removeFromCart: (productId: string) => void;
  updateQuantity: (productId: string, quantity: number) => void;
  clearCart: () => void;
  total: number;
  itemCount: number;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [items, setItems] = useState<CartItem[]>(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(items));
  }, [items]);

  const addToCart = (product: AddToCartItem) => {
    setItems((currentItems) => {
      const uniqueId =
        product.type === 'ENTRY_TICKETS' || product.type === 'OTHER_SERVICES'
          ? `${product.id}-${product.ticketType}-${product.date}-${product.hour}`
          : `${product.id}-${product.date}-${product.hour}`;

      const isParkingItem = (item: CartItem): item is ParkingCartItem =>
        item.type === 'PARKING_TICKETS';

      const isTicketItem = (item: CartItem): item is TicketCartItem =>
        item.type === 'ENTRY_TICKETS' ||
        item.type === 'OTHER_SERVICES' ||
        item.type === 'ACCOMMODATION';

      const existingItem = currentItems.find((item) => {
        if (product.type === 'PARKING_TICKETS' && isParkingItem(item)) {
          // Sprawdzamy czy tablice numerów rejestracyjnych są identyczne
          return (
            item.id === uniqueId &&
            JSON.stringify(item.registrationNumbers.sort()) ===
              JSON.stringify(product.registrationNumbers?.sort() || [])
          );
        } else if (
          (product.type === 'ENTRY_TICKETS' ||
            product.type === 'OTHER_SERVICES' ||
            product.type === 'ACCOMMODATION') &&
          isTicketItem(item)
        ) {
          return (
            item.id === uniqueId &&
            item.type === product.type &&
            item.ticketType === product.ticketType
          );
        }
        return false;
      });

      if (existingItem) {
        return currentItems.map((item) =>
          item.id === uniqueId
            ? { ...item, quantity: item.quantity + product.quantity }
            : item
        );
      }

      // Przygotowanie wspólnych właściwości dla wszystkich typów przedmiotów
      const baseItemProperties = {
        id: uniqueId,
        name: product.name,
        price: product.price,
        quantity: product.quantity,
        date: product.date,
        hour: product.hour,
        description: product.description || '',
        fullDescription: product.fullDescription || '',
        coverImageUrl: product.coverImageUrl || '',
        consumerCategories:
          product.consumerCategories?.map((category) => ({
            ...category,
            description: category.description || '',
          })) || [],
        requirePersonalData: product.requirePersonalData || false,
      };

      let newItem: CartItem;

      // Tworzenie odpowiedniego typu przedmiotu w zależności od typu produktu
      if (product.type === widgetProductType.PARKING_TICKETS) {
        newItem = {
          ...baseItemProperties,
          type: widgetProductType.PARKING_TICKETS,
          registrationNumbers: product.registrationNumbers || [],
        } as ParkingCartItem;
      } else {
        newItem = {
          ...baseItemProperties,
          type: product.type as
            | widgetProductType.ENTRY_TICKETS
            | widgetProductType.ACCOMMODATION
            | widgetProductType.OTHER_SERVICES,
          ticketType: product.ticketType,
        } as TicketCartItem;
      }

      return [...currentItems, newItem];
    });
  };

  const removeFromCart = (productId: string) => {
    setItems((currentItems) =>
      currentItems.filter((item) => item.id !== productId)
    );
  };

  const updateQuantity = (productId: string, quantity: number) => {
    if (quantity < 1) return;

    setItems((currentItems) =>
      currentItems.map((item) =>
        item.id === productId ? { ...item, quantity } : item
      )
    );
  };

  const clearCart = () => {
    setItems([]);
  };

  // Obliczanie sumy
  // PRICE
  const total = items.reduce(
    (sum, item) =>
      sum +
      (Number(item?.consumerCategories?.[0]?.price?.grossAmount) || 0) *
        item.quantity,
    0
  );

  // Obliczanie liczby przedmiotów
  const itemCount = items.reduce((count, item) => count + item.quantity, 0);

  const value = {
    items,
    addToCart,
    removeFromCart,
    updateQuantity,
    clearCart,
    total,
    itemCount,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
