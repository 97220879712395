import React, { useState } from 'react';
import { useCart } from '../../../context/CartProvider';
import { useTheme } from '../../../context/ThemeContext';
import { useBuyerData } from '../../../context/BuyerDataContext';
import { BuyerFormData } from '../../../types';
import {
  BergButton,
  BergCardProduct,
  BergCustomerDataSummary,
} from '@berg-regions/berg-components';
import './Summary.scss';
import { useTranslation } from 'react-i18next';

interface SummaryProps {
  onBack: () => void;
  onContinue: () => void;
}

interface FormErrors {
  [key: string]: string;
}

const Summary: React.FC<SummaryProps> = ({ onBack, onContinue }) => {
  const { t } = useTranslation();
  const { items, total } = useCart();
  const { theme } = useTheme();
  const { buyerData, updateBuyerData } = useBuyerData();
  const { companyData } = useBuyerData();
  const [errors, setErrors] = useState<FormErrors>({});
  const [termsAccepted, setTermsAccepted] = useState<boolean>(
    buyerData.acceptTerms || false
  );

  const hoverColor = theme.colors.primary;
  const backgroundColor = theme.colors.secondary;

  const hoverStyle = {
    '--hover-color': hoverColor,
  } as React.CSSProperties;

  const backgroundStyle = {
    '--background-color': backgroundColor,
  } as React.CSSProperties;

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setTermsAccepted(checked);

    updateBuyerData({ [name]: checked } as Partial<BuyerFormData>);

    if (errors[name]) {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};

    if (!termsAccepted) {
      newErrors.acceptTerms = t('summary.you_must_conditions');
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleProceedToPayment = () => {
    if (!validateForm()) {
      return;
    }

    if (window.parent && window.parent !== window) {
      try {
        window.parent.postMessage(
          {
            type: 'PROCEED_TO_PAYMENT',
            data: {
              buyerData,
              orderItems: items,
              totalAmount: total,
            },
          },
          '*' // todo dodoac witryny platonosci
        );
      } catch (error) {
        console.error(
          'Nie udało się wysłać danych do nadrzędnej aplikacji',
          error
        );
      }
    }

    onContinue();
  };

  const customerData = {
    name: buyerData.firstName,
    lastName: buyerData.lastName,
    mail: buyerData.email,
    phone: buyerData.phone,
  };

  const formCompanyData = {
    nip: companyData.nip,
    name: companyData.name,
    city: companyData.city,
    street: companyData.street,
    postalCode: companyData.postalCode,
    houseNumber: companyData.houseNumber,
  };

  const labelCustomerData = [
    t('buyer_data.name'),
    t('buyer_data.surname'),
    t('buyer_data.email'),
    t('buyer_data.phone'),
  ];
  const labelInvoiceData = [
    t('buyer_data.tax_id'),
    t('buyer_data.company_name'),
    t('buyer_data.address'),
  ];

  return (
    <div className="summary flex flex-col md:flex-row gap-6 sm:mb-0 mb-[65%]">
      <div className="w-full md:w-2/3 bg-white rounded-lg p-4 shadow-sm">
        <h2 className="text-xl font-semibold mb-4">
          {t('summary.order_summary')}
        </h2>

        <div className="space-y-4">
          {/* Dane zamawiającego */}
          <div className="border-b pb-4">
            <h3 className="font-medium mb-2">{t('summary.buyer_data')}</h3>
            <BergCustomerDataSummary
              label={labelCustomerData}
              data={customerData}
              invoiceLabelHeader={t('summary.invoice_data')}
              invoiceLabel={labelInvoiceData}
              invoiceData={formCompanyData}
            />
            {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-2 text-sm">
              <div>
                <span className="text-gray-600">Imię i nazwisko:</span>
                <p className="font-medium">
                  {buyerData.firstName} {buyerData.lastName}
                </p>
              </div>
              <div>
                <span className="text-gray-600">Email:</span>
                <p className="font-medium">{buyerData.email}</p>
              </div>
              <div>
                <span className="text-gray-600">Telefon:</span>
                <p className="font-medium">{buyerData.phone}</p>
              </div>
            </div> */}
          </div>

          {/* Szczegóły zamówienia */}
          <div>
            <h3 className="font-medium mb-2">{t('summary.order_details')}</h3>
            <div className="space-y-3">
              {items.map((item) => (
                <div
                  key={item.id}
                  className="flex justify-between border-b pb-2"
                >
                  <BergCardProduct
                    productName={item.name}
                    productDate={item.date}
                    typesNumberOfDay={item.hour}
                    // productTotalPrice={`${
                    //   item.quantity
                    // } x ${item.price.toString()}zł`}
                    productTotalPrice={`${item.quantity} x ${1}zł`}
                    isTrash={false}
                    handleDelete={console.log}
                  />
                </div>
              ))}
            </div>

            <div className="flex justify-between mt-4 font-bold text-xl">
              <span>{t('summary.total')}:</span>
              <span>{total.toFixed(2)} zł</span>
            </div>
          </div>
        </div>

        <div className="mt-6 p-4 bg-gray-50 rounded-lg border border-gray-200">
          <h3 className="font-medium mb-3">Regulamin zakupów</h3>
          <div className="max-h-40 overflow-y-auto mb-3 text-sm text-gray-600">
            <p className="mb-2">
              Poniżej przedstawiamy najważniejsze informacje dotyczące zakupu
              biletów do {theme.client}:
            </p>
            <ol className="list-decimal pl-5 space-y-1">
              <li>Zakupione bilety są imienne i nie podlegają wymianie.</li>
              <li>
                Bilety upoważniają do jednorazowego wstępu w wybranym dniu i
                godzinie.
              </li>
              <li>
                W przypadku rezygnacji z zakupu, zwrot środków możliwy jest do
                48 godzin przed planowaną wizytą.
              </li>
              <li>
                Osoby niepełnoletnie mogą wejść tylko pod opieką osoby dorosłej.
              </li>
              <li>
                Na terenie obiektu należy przestrzegać regulaminu {theme.client}
                .
              </li>
              <li>
                Organizator zastrzega sobie prawo do zmiany programu i godzin
                otwarcia w uzasadnionych przypadkach.
              </li>
            </ol>
          </div>

          <div className="flex items-start mt-3">
            <input
              type="checkbox"
              id="acceptTerms"
              name="acceptTerms"
              checked={termsAccepted}
              onChange={handleCheckboxChange}
              className="mt-1"
            />
            <label htmlFor="acceptTerms" className="ml-2 text-sm">
              {t('summary.accept_terms')}
            </label>
          </div>
          {errors.acceptTerms && (
            <p className="text-red-500 text-xs mt-1">{errors.acceptTerms}</p>
          )}
        </div>

        {/* Przyciski nawigacji */}
        <div className="flex flex-col sm:flex-row gap-3 mt-6">
          <BergButton
            bg="transparent"
            border="black"
            color="black"
            animation={true}
            onClick={onBack}
            className="cursor-pointer"
          >
            {t('back')}
          </BergButton>
          <span style={{ ...hoverStyle, ...backgroundStyle }}>
            <BergButton
              bg="secondary"
              border="white"
              onClick={handleProceedToPayment}
              animation={true}
              disabled={!termsAccepted}
              className="cursor-pointer"
            >
              {t('pay_order')}
            </BergButton>
          </span>
        </div>
      </div>

      {/* Informacje dodatkowe */}
      <div className="w-full md:w-1/3">
        <div className="bg-white rounded-lg p-4 shadow-sm">
          <h3 className="text-lg font-semibold mb-3">
            {t('summary.information_additional.title')}
          </h3>

          <div className="space-y-3 text-sm">
            <p>{t('summary.information_additional.text1')}</p>
            <p>
              {t('summary.information_additional.text2')}{' '}
              <strong>{buyerData.email}</strong>
            </p>
            <p>
              {t('summary.information_additional.text3')}{' '}
              <strong>
                <a href={`tel:${theme.phone}`}>{theme.phone}</a>
              </strong>
            </p>
          </div>

          <div className="mt-4 p-3 bg-blue-50 rounded-lg border border-blue-100 text-sm">
            <p className="font-medium text-blue-700 mb-1">
              {t('summary.information_additional.info_pay')}
            </p>
            <p className="text-blue-600">
              {t('summary.information_additional.reservation')}
            </p>
          </div>

          <p className="text-sm text-gray-500 mt-4">
            {t('summary.information_additional.service')} {theme.client}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Summary;
