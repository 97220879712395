import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '../assets/translations/en.json';
import pl from '../assets/translations/pl.json';
import de from '../assets/translations/de.json';
import cz from '../assets/translations/cz.json';
import sk from '../assets/translations/sk.json';
import ua from '../assets/translations/ua.json';

const resources = {
  en,
  pl,
  de,
  cz,
  sk,
  ua,
};

i18n.use(initReactI18next).init({
  resources,
  debug: true,
  fallbackLng: 'pl',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
