import React, { createContext, useContext, useState, useEffect } from 'react';
import i18n from './i18n';

interface I18nContextType {
  language: string;
  changeLanguage: (lang: string) => void;
  supportedLanguages: string[];
  defaultLanguage: string;
  setSupportedLanguages: (languages: string[]) => void;
  setDefaultLanguage: (language: string) => void;
}

const I18nContext = createContext<I18nContextType>({
  language: 'pl',
  changeLanguage: (lang: string) => {
    console.warn(
      `changeLanguage method not implemented. Tried to change to: ${lang}`
    );
  },
  supportedLanguages: ['pl'],
  defaultLanguage: 'pl',
  setSupportedLanguages: () => {
    console.warn('setSupportedLanguages method not implemented');
  },
  setDefaultLanguage: () => {
    console.warn('setDefaultLanguage method not implemented');
  },
});

export const useI18n = () => useContext(I18nContext);

interface I18nProviderProps {
  children: React.ReactNode;
}

export const I18nProvider: React.FC<I18nProviderProps> = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState<string>(
    i18n.language || 'pl'
  );

  const [supportedLanguages, setSupportedLanguages] = useState<string[]>([
    'pl',
  ]);
  const [defaultLanguage, setDefaultLanguage] = useState<string>('pl');

  const changeLanguage = (lang: string) => {
    if (supportedLanguages.includes(lang) || supportedLanguages.length === 0) {
      i18n.changeLanguage(lang);
      setCurrentLanguage(lang);
      localStorage.setItem('preferredLanguage', lang);
    } else {
      console.warn(
        `Language ${lang} is not supported. Using default language ${defaultLanguage}`
      );
      i18n.changeLanguage(defaultLanguage);
      setCurrentLanguage(defaultLanguage);
      localStorage.setItem('preferredLanguage', defaultLanguage);
    }
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('preferredLanguage');
    const urlParams = new URLSearchParams(window.location.search);
    const urlLanguage = urlParams.get('lang');

    let initialLanguage =
      urlLanguage || savedLanguage || defaultLanguage || 'pl';

    if (
      supportedLanguages.length > 0 &&
      !supportedLanguages.includes(initialLanguage)
    ) {
      initialLanguage = defaultLanguage || supportedLanguages[0] || 'pl';
    }

    if (initialLanguage !== currentLanguage) {
      changeLanguage(initialLanguage);
    }
  }, [currentLanguage, supportedLanguages, defaultLanguage]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data && event.data.type === 'SET_LANGUAGE') {
        changeLanguage(event.data.language);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [supportedLanguages, defaultLanguage]);

  const value: I18nContextType = {
    language: currentLanguage,
    changeLanguage,
    supportedLanguages,
    defaultLanguage,
    setSupportedLanguages,
    setDefaultLanguage,
  };

  return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>;
};
