import React, { useEffect } from 'react';
import { Category } from '../../../types';
import ProductCard from './ProductCard';
import './ProductGrid.scss';
import axiosInstance from '../../../services/axios';

interface ProductGridProps {
  onNavigateToCategory: (product: Category) => void;
  productCategories: Category[] | undefined;
}

const ProductGrid = ({
  onNavigateToCategory,
  productCategories,
}: ProductGridProps) => {
  useEffect(() => {
    const fetchWidgetData = async (widgetId: string) => {
      if (!widgetId) {
        throw new Error('Nie podano widgetId');
      }

      try {
        const response = await axiosInstance.get(
          `/v2/external/widget/${widgetId}`
        );
        console.log(response.data);

        return response.data;
      } catch (error) {
        console.error('Błąd podczas pobierania danych widgetu:', error);
        throw error;
      }
    };

    fetchWidgetData('1');
  }, []);

  return (
    <div className="w-full">
      <div className="product-grid">
        {productCategories &&
          productCategories.map((product) => (
            <ProductCard
              key={product.name}
              product={product}
              onNavigateToCategory={() => onNavigateToCategory(product)}
            />
          ))}
      </div>
    </div>
  );
};
export default ProductGrid;
