/* eslint-disable max-lines */
/* eslint-disable complexity */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  Category,
  ConsumerCategories,
  DailyAvailability,
  ProductItem,
} from '../../../types';
import back from '../../../assets/icon/back.png';
import { BergCounter, BergSeparator } from '@berg-regions/berg-components';
import './ProductFormModal.scss';
import { CalendarDays, Info, InfoIcon, X } from 'lucide-react';
import { useTheme } from '../../../context/ThemeContext';
import blue_1 from '../../../assets/icon/1-blue.png';
import Calendar from '../../calendar/Calendar';
import TimeSelector from '../../calendar/TimeSelector';
import { useTranslation } from 'react-i18next';
import { getCurrency } from '../../../hooks/currencyUtils';
import { ProductApi } from '../../../services/apiService';
import { useWidget } from '../../../context/WidgetContext';

interface ProductFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formData: FormData) => void;
  product: ProductItem;
  category: Category;
}

interface FormData {
  tickets: {
    [key: string]: ConsumerCategories & {
      quantity: number;
      isSelected: boolean;
    };
  };
  date: string | null;
  time: string;
}

const TicketFormModal = ({
  isOpen,
  onClose,
  onSubmit,
  product,
  category,
}: ProductFormModalProps) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { widgetId } = useWidget();
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [, setQuantity] = useState<number>(1);
  const [formData, setFormData] = useState<FormData>({
    tickets: {},
    date: null,
    time: '',
  });
  const [availability, setAvailability] = useState<DailyAvailability[]>([]);
  const [isLoadingAvailability, setIsLoadingAvailability] =
    useState<boolean>(false);
  const [currentMonth, setCurrentMonth] = useState<{
    year: number;
    month: number;
  }>({
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
  });
  const isFirstRender = useRef(true);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (isOpen && product && product.consumerCategories) {
      const tickets = product.consumerCategories.reduce((acc, category) => {
        acc[category.id] = {
          ...category,
          quantity: 1,
          isSelected: false,
        };
        return acc;
      }, {} as FormData['tickets']);

      setFormData({
        tickets,
        date: null,
        time: '',
      });
      setSelectedDate(null);
      setSelectedTime('');
      setQuantity(1);

      const now = new Date();
      const initialYear = now.getFullYear();
      const initialMonth = now.getMonth();
      setCurrentMonth({ year: initialYear, month: initialMonth });
      if (isOpen && product && product.productId) {
        loadAvailability(initialYear, initialMonth);
      }
    }
  }, [isOpen, product]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (isOpen && product?.productId) {
      loadAvailability(currentMonth.year, currentMonth.month);
    }
  }, [currentMonth.year, currentMonth.month, isOpen, product]);

  const loadAvailability = async (year: number, month: number) => {
    if (!widgetId || !category?.id || !product?.productId) {
      console.error('Missing required data for availability');
      return;
    }
    setIsLoadingAvailability(true);
    try {
      const firstDay = new Date(year, month, 1);
      const lastDay = new Date(year, month + 1, 0);
      const fromDate = new Date(firstDay);
      fromDate.setDate(fromDate.getDate() - 7);
      const toDate = new Date(lastDay);
      toDate.setDate(toDate.getDate() + 7);
      const from = fromDate.toISOString().split('T')[0];
      const to = toDate.toISOString().split('T')[0];

      const data = await ProductApi.getProductAvailability(
        widgetId,
        category.id,
        product.productId,
        from,
        to
      );
      setAvailability(data);
    } catch (error) {
      console.error('Error fetching availability:', error);
      setAvailability([]);
    } finally {
      setIsLoadingAvailability(false);
    }
  };

  const handleMonthChange = useCallback((year: number, month: number) => {
    setCurrentMonth((prev) => {
      if (prev.year === year && prev.month === month) return prev;
      return { year, month };
    });
  }, []);

  const handleClose = () => {
    onClose();
  };

  if (!isOpen) return null;

  const handleTimeSelect = (time: string) => {
    setSelectedTime(time);
    setFormData((prev) => ({
      ...prev,
      time,
    }));
  };

  const handleTicketSelect = (ticketId: string) => {
    setFormData((prev) => ({
      ...prev,
      tickets: {
        ...prev.tickets,
        [ticketId]: {
          ...prev.tickets[ticketId],
          isSelected: !prev.tickets[ticketId].isSelected,
        },
      },
    }));
  };

  const handleQuantityChange = (ticketId: string, newValue: number) => {
    setFormData((prev) => ({
      ...prev,
      tickets: {
        ...prev.tickets,
        [ticketId]: {
          ...prev.tickets[ticketId],
          quantity: newValue,
        },
      },
    }));
  };

  const isAnyTicketSelected = () =>
    Object.values(formData.tickets).some((ticket) => ticket.isSelected);

  const handleAddToCart = () => {
    if (selectedDate && selectedTime) {
      const selectedTickets = Object.values(formData.tickets).filter(
        (ticket) => ticket.isSelected
      );

      if (selectedTickets.length === 0) {
        // Add some error message
        return;
      }

      const updatedFormData = {
        ...formData,
        date: selectedDate,
        time: selectedTime,
      };
      onSubmit(updatedFormData);
      onClose();
    }
  };

  const getDefaultCurrency = (): string => {
    const firstTicket = Object.values(formData.tickets)[0];
    if (firstTicket && firstTicket.price) {
      return firstTicket.price.currency;
    }
    return 'PLN';
  };

  const missingFields: string[] = [];
  if (!selectedDate) missingFields.push(t('choose_date', 'Wybierz datę'));
  if (!selectedTime) missingFields.push(t('choose_time', 'Wybierz godzinę'));
  if (!isAnyTicketSelected())
    missingFields.push(t('choose_ticket_package', 'Wybierz pakiet cenowy'));
  const errorMessage = missingFields.join(', ');

  const handleMouseEnter = () => {
    if (!selectedDate || !selectedTime || !isAnyTicketSelected()) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className="product-form-modal absolute inset-x-0 top-0 bottom-0 bg-[#F0F0F0CC] backdrop-blur-lg rounded-none sm:rounded-xl border-0 border-transparent flex items-start justify-center z-50">
      <div className="modal-content w-full m-1 sm:m-4 relative max-h-full overflow-y-auto">
        <div className="flex flex-col sm:flex-row gap-6">
          <div className="w-full sm:w-1/2 flex flex-row gap-4">
            <div className="w-[96%] sm:w-full flex flex-col">
              <div className="flex flex-row gap-4 w-full">
                <img
                  src={back}
                  className="w-[40px] h-[fit-content] cursor-pointer hidden sm:block "
                  onClick={handleClose}
                  alt="Back"
                />
                <div className="flex flex-col gap-3 w-full">
                  <div className="flex flex-row gap-1 items-start justify-between">
                    <div className="text-[21px] font-bold self-center">
                      {product.name}
                    </div>
                    <div
                      onClick={handleClose}
                      className="bg-white rounded-xl sm:hidden block p-2 m-1 w-[40px] h-[41px] cursor-pointer"
                    >
                      <X />
                    </div>
                  </div>

                  <div className="flex flex-col gap-2">
                    {Object.values(formData.tickets).map((ticket) => (
                      <div
                        key={ticket.id}
                        className="bg-white w-full px-3 py-2 rounded-md flex flex-row gap-4"
                      >
                        <input
                          type="checkbox"
                          checked={ticket.isSelected}
                          onChange={() => handleTicketSelect(ticket.id)}
                        />
                        <div className="flex flex-col gap-2 w-full">
                          <div className="flex flex-col leading-5">
                            <strong className="text-[19px]">
                              {ticket.name}
                            </strong>
                            <div className="font-light text-[13px] text-[#7E82A6]">
                              {ticket.description}
                            </div>
                          </div>
                          <div className="flex flex-col gap-1 sm:gap-0 sm:flex-row justify-between">
                            <div className="flex flex-col leading-5">
                              <strong className="text-[22px]">
                                {ticket.price.grossAmount} {getCurrency(ticket)}
                              </strong>
                              <div className="font-light text-[13px] text-[#7E82A6]">
                                {t('ticket_modal.desc_price')}{' '}
                                {ticket.lowestPriceIn30Days.grossAmount}{' '}
                                {getCurrency(ticket)}
                              </div>
                            </div>
                            <div className="product-form-modal-counter">
                              {ticket.isSelected && (
                                <BergCounter
                                  onChange={(value) =>
                                    handleQuantityChange(ticket.id, value)
                                  }
                                  value={ticket.quantity}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="hidden sm:flex product-form-modal-extras flex flex-col gap-2 font-light">
                    {selectedDate && (
                      <div className="flex flex-row gap-3 content-center">
                        <CalendarDays />
                        <div>
                          {t('ticket_modal.lead_time')}:{' '}
                          <strong>{selectedDate}</strong>{' '}
                          {selectedTime && <span> {selectedTime}</span>}
                        </div>
                      </div>
                    )}
                    <div className="flex flex-row gap-3 content-center">
                      <InfoIcon className="w-[19%] self-center" />{' '}
                      {product.description || 'Brak lokalizacji'}
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden sm:flex product-form-modal-info flex-row gap-2 items-end mt-auto text-[#7E82A6] text-[14px] font-light">
                <Info /> {t('service_operated')} {theme.client}
              </div>
            </div>
          </div>
          <div id="scroll-to-bottom" className="w-full sm:w-1/2">
            <div className="sm:hidden block font-light text-[15px] mb-2 text-[#7E82A6]">
              {t('ticket_modal.order_details')}
            </div>
            <div className="bg-white w-full rounded-xl p-2 sm:p-4">
              <div className="flex flex-row gap-4 items-center">
                <img src={blue_1} alt="Step 1" />
                <p className="text-[20px] font-semibold">
                  {t('ticket_modal.lead_time')}
                </p>
              </div>

              <BergSeparator
                width={'100%'}
                height={'1px'}
                color={'#12152C26'}
                margin="10px 0 10px 0"
              />
              <div className="flex flex-col gap-3">
                <div>
                  {isLoadingAvailability ? (
                    <div className="flex justify-center items-center p-4">
                      <span className="text-lg">{t('loading')}</span>
                    </div>
                  ) : (
                    <>
                      <Calendar
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        prices={{}} // We can add prices later
                        availability={availability}
                        currency={getDefaultCurrency()}
                        onChangeMonth={handleMonthChange}
                        currentDate={
                          new Date(currentMonth.year, currentMonth.month, 1)
                        }
                      />

                      <TimeSelector
                        onTimeSelect={handleTimeSelect}
                        selectedTime={selectedTime}
                        selectedDate={selectedDate}
                        availability={availability}
                      />
                    </>
                  )}
                  <div
                    className="relative"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <button
                      onClick={handleAddToCart}
                      disabled={
                        !selectedDate ||
                        !selectedTime ||
                        !isAnyTicketSelected() ||
                        isLoadingAvailability
                      }
                      className={`w-full mt-4 py-2 px-4 rounded-full text-[18px] font-semibold cursor-pointer transition-colors ${
                        !selectedDate ||
                        !selectedTime ||
                        !isAnyTicketSelected() ||
                        isLoadingAvailability
                          ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                          : 'text-black border-0 hover:brightness-90'
                      }`}
                      style={
                        !selectedDate ||
                        !selectedTime ||
                        !isAnyTicketSelected() ||
                        isLoadingAvailability
                          ? {}
                          : { backgroundColor: theme.colors.secondary }
                      }
                    >
                      {t('add_to_basket')}
                    </button>
                    {showTooltip &&
                      (!selectedDate ||
                        !selectedTime ||
                        !isAnyTicketSelected()) && (
                        <div
                          style={{
                            position: 'absolute',
                            bottom: '110%',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            backgroundColor: 'black',
                            color: 'white',
                            padding: '5px 10px',
                            borderRadius: '4px',
                            whiteSpace: 'nowrap',
                            zIndex: 10,
                          }}
                        >
                          {errorMessage}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketFormModal;
