import React, { useEffect } from 'react';
import { DailyAvailability } from '../../types';

interface TimeSelectorProps {
  onTimeSelect: (time: string) => void;
  selectedTime?: string;
  selectedDate?: string | null;
  availability?: DailyAvailability[];
}

interface TimeSlot {
  label: string;
  value: string;
  available: boolean;
  availableSeats?: number;
}

const TimeSelector = ({
  onTimeSelect,
  selectedTime,
  selectedDate,
  availability = [],
}: TimeSelectorProps) => {
  const getTimeSlots = (): TimeSlot[] => {
    if (!selectedDate) {
      return [];
    }

    // Znajdź dane dostępności dla wybranej daty
    const dateAvailability = availability.find((a) => a.date === selectedDate);

    if (!dateAvailability) {
      // Jeśli brak danych o dostępności, zwróć pustą tablicę - brak wyboru godzin
      return [
        {
          label: 'Cały dzień',
          value: 'Cały dzień',
          available: true,
        },
      ];
    }

    // Jeśli unlimited jest true, zawsze dostępne
    const isUnlimited = dateAvailability.unlimited === true;

    // Jeśli typ dostępności to WHOLE_DAY lub brak hourlyAvailability, nie pokazuj wyboru godzin
    if (
      dateAvailability.type === 'WHOLE_DAY' ||
      !dateAvailability.hourlyAvailability ||
      dateAvailability.hourlyAvailability.length === 0
    ) {
      // Sprawdź ogólną dostępność dla całego dnia
      let isAvailable = isUnlimited;
      let availableSeats: number | undefined = undefined;

      if (
        !isUnlimited &&
        dateAvailability.maxCapacity !== null &&
        dateAvailability.soldSeats !== null
      ) {
        isAvailable = dateAvailability.soldSeats < dateAvailability.maxCapacity;
        availableSeats =
          dateAvailability.maxCapacity - dateAvailability.soldSeats;
      }

      // Zwróć jedną opcję dla całego dnia
      return [
        {
          label: 'Cały dzień',
          value: 'Cały dzień',
          available: isAvailable,
          availableSeats: isUnlimited ? undefined : availableSeats,
        },
      ];
    }

    // Dla typu TIME_SLOT, zwróć dostępne godziny z informacją o zajętości
    return dateAvailability.hourlyAvailability
      .map((slot) => {
        // Usuń sekundy z formatu czasu jeśli są obecne
        const startTime = slot.startTime.includes(':00.')
          ? slot.startTime.substring(0, 5)
          : slot.startTime;

        const endTime = slot.endTime.includes(':00.')
          ? slot.endTime.substring(0, 5)
          : slot.endTime;

        const isSlotAvailable =
          isUnlimited || slot.soldSeats < slot.maxCapacity;
        const availableSeats = isUnlimited
          ? undefined
          : slot.maxCapacity - slot.soldSeats;

        return {
          label: `${startTime} - ${endTime}`,
          value: `${startTime} - ${endTime}`,
          available: isSlotAvailable,
          availableSeats,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label)); // Sortuj po godzinie
  };

  const timeSlots = getTimeSlots();
  const isDateSelected = !!selectedDate;

  // Automatycznie wybierz pierwszą dostępną godzinę, jeśli nie wybrano żadnej, a data jest wybrana
  useEffect(() => {
    if (
      isDateSelected &&
      (!selectedTime || selectedTime === '') &&
      timeSlots.length > 0
    ) {
      const firstAvailableSlot = timeSlots.find((slot) => slot.available);
      if (firstAvailableSlot) {
        onTimeSelect(firstAvailableSlot.value);
      }
    }
  }, [isDateSelected, selectedTime, timeSlots, onTimeSelect]);

  if (!isDateSelected || timeSlots.length === 0) {
    return null;
  }

  // Jeśli jest tylko jeden dostępny slot czasowy, nie pokazuj selektora
  if (timeSlots.length === 1) {
    return (
      <div className="mt-4 flex flex-row items-center gap-4">
        <p className="text-[17px] font-normal">
          Godzina: <span className="font-medium">{timeSlots[0].label}</span>
          {timeSlots[0].availableSeats !== undefined && (
            <span className="ml-2 text-sm text-blue-500">
              (Dostępne miejsca: {timeSlots[0].availableSeats})
            </span>
          )}
        </p>
      </div>
    );
  }

  return (
    <div className="mt-4 flex flex-col sm:flex-row items-start sm:items-center gap-3">
      <label className="text-[17px] font-normal">Wybierz godzinę:</label>
      <div className="w-full sm:w-1/2">
        <select
          value={selectedTime}
          onChange={(e) => onTimeSelect(e.target.value)}
          className="w-full p-2 bg-white rounded-lg border-gray-300 text-[17px] font-light"
          disabled={!isDateSelected}
        >
          <option value="" disabled>
            Wybierz godzinę
          </option>
          {timeSlots.map((slot) => (
            <option
              key={slot.value}
              value={slot.value}
              disabled={!slot.available}
            >
              {slot.label}
              {slot.availableSeats !== undefined && slot.available
                ? ` (Dostępne miejsca: ${slot.availableSeats})`
                : slot.available
                ? ''
                : ' - Brak miejsc'}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default TimeSelector;
