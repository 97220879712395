import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BergLanguageSelection } from '@berg-regions/berg-components';
import { useI18n } from '../../context/i18nContext';

interface LanguageOption {
  value: string;
  label: string;
}

interface LanguageSelectionProps {
  supportedLanguages?: string[];
  defaultLanguage?: string;
}

const LanguageSelection: React.FC<LanguageSelectionProps> = ({
  supportedLanguages = ['pl', 'en', 'de'],
  defaultLanguage = 'pl',
}) => {
  const { i18n } = useTranslation() as any;
  const { language, changeLanguage } = useI18n();

  useEffect(() => {
    if (supportedLanguages && supportedLanguages.length > 0) {
      if (!supportedLanguages.includes(language)) {
        const newLang = defaultLanguage || supportedLanguages[0];
        changeLanguage(newLang);
      }
    }
  }, [supportedLanguages, defaultLanguage, language, changeLanguage]);

  const getLanguageLabel = (locale: string): string => {
    const languageLabels: Record<string, string> = {
      pl: 'Polski',
      de: 'Deutsch',
      en: 'English',
      fr: 'Français',
      es: 'Español',
      it: 'Italiano',
      ru: 'Русский',
      cs: 'Čeština',
      sk: 'Slovenčina',
      ua: 'Українська',
    };

    return languageLabels[locale] || locale;
  };

  if (!supportedLanguages || supportedLanguages.length <= 1) {
    return null;
  }

  const languageOptions: LanguageOption[] = supportedLanguages.map(
    (locale) => ({
      value: locale,
      label: getLanguageLabel(locale),
    })
  );

  const defaultOption =
    languageOptions.find((opt) => opt.value === language) ||
    languageOptions.find((opt) => opt.value === defaultLanguage) ||
    languageOptions[0];

  const handleLanguageChange = async (selectedOption: LanguageOption) => {
    const selectedLocale = selectedOption.value;

    await i18n.changeLanguage(selectedLocale);
    changeLanguage(selectedLocale);

    if (window.parent) {
      window.parent.postMessage(
        {
          type: 'LANGUAGE_CHANGE',
          language: selectedLocale,
        },
        '*'
      );
    }
  };

  if (languageOptions.length === 0) {
    return null;
  }

  return (
    <div className="language-selector px-2">
      <BergLanguageSelection
        options={languageOptions}
        onChange={handleLanguageChange}
        defaultOption={defaultOption}
      />
    </div>
  );
};

export default LanguageSelection;
