import { Availability } from '../types';
import axiosInstance from './axios';

export const WidgetApi = {
  getWidgetData: async (widgetId: string) => {
    if (!widgetId) {
      throw new Error('Nie podano widgetId');
    }
    const response = await axiosInstance.get(`/v2/external/widget/${widgetId}`);
    return response.data;
  },
};

export const ProductApi = {
  getProductsByCategory: async (widgetId: number, categoryId: number) => {
    const response = await axiosInstance.get(
      `/v2/external/widget/${widgetId}/product-category/${categoryId}/products`
    );
    return response.data;
  },

  getProductAvailability: async (
    widgetId: string | number,
    productCategoryId: string | number,
    productId: string | number,
    from: string,
    to: string
  ): Promise<Availability> => {
    const response = await axiosInstance.get(
      `/v2/external/widget/${widgetId}/product-category/${productCategoryId}/product/${productId}/availability`,
      {
        params: { from, to },
      }
    );
    return response.data;
  },
};

// export const CartApi = {
//   createCart: async (widgetId: string) => {
//     const response = await axiosInstance.post(
//       `/v2/external/widget/${widgetId}/cart`
//     );
//     return response.data;
//   },

//   addToCart: async (widgetId: string, cartId: string, productData: string) => {
//     const response = await axiosInstance.post(
//       `/v2/external/widget/${widgetId}/cart/${cartId}/items`,
//       productData
//     );
//     return response.data;
//   },

//   getCartItems: async (widgetId: string, cartId: string) => {
//     const response = await axiosInstance.get(
//       `/v2/external/widget/${widgetId}/cart/${cartId}`
//     );
//     return response.data;
//   },
// };

export const CheckoutApi = {};
