import { I18nextProvider } from 'react-i18next';
import WidgetLayout from './components/layouts/WidgetLayout';
import { BuyerDataProvider } from './context/BuyerDataContext';
import { CartProvider } from './context/CartProvider';
import { ClientConfigProvider } from './context/ClientConfigContext';
import { ThemeProvider } from './context/ThemeContext';
import '@berg-regions/berg-components/lib/index.css';
import i18n from './context/i18n';
import { I18nProvider } from './context/i18nContext';
import { WidgetProvider } from './context/WidgetContext';

const App = () => (
  <I18nextProvider i18n={i18n}>
    <WidgetProvider>
      <ClientConfigProvider>
        <ThemeProvider>
          <I18nProvider>
            <CartProvider>
              <BuyerDataProvider>
                <WidgetLayout />
              </BuyerDataProvider>
            </CartProvider>
          </I18nProvider>
        </ThemeProvider>
      </ClientConfigProvider>{' '}
    </WidgetProvider>
  </I18nextProvider>
);

export default App;
