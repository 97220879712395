import React from 'react';
import * as Icons from 'lucide-react';
import { LucideIcon } from 'lucide-react';

export const mapIconToComponent = (iconName: string): React.ReactNode => {
  const pascalCaseName = iconName
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');

  const IconComponent = Icons[pascalCaseName as keyof typeof Icons] as
    | LucideIcon
    | undefined;

  if (!IconComponent) return null;
  return React.createElement(IconComponent, { size: 24 });
};

export const convertDescriptionToBergFormat = (
  description: string | undefined
): { icon: React.ReactNode; text: string }[] | undefined => {
  if (!description) return undefined;

  try {
    const parsedDescription = JSON.parse(description);
    if (Array.isArray(parsedDescription)) {
      return parsedDescription.map((item) => ({
        ...item,
        icon:
          typeof item.icon === 'string'
            ? mapIconToComponent(item.icon)
            : item.icon,
      }));
    }
  } catch (e) {
    console.log(e);
  }

  return [
    {
      icon: mapIconToComponent('info'),
      text: description,
    },
  ];
};

export const convertHtmlDescriptionToBergFormat = (
  htmlDescription: string | undefined
): { icon: React.ReactNode; text: string }[] | undefined => {
  if (!htmlDescription) return undefined;

  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlDescription, 'text/html');
    const listItems = doc.querySelectorAll('li');

    if (listItems.length > 0) {
      return Array.from(listItems).map((item, index) => ({
        icon: mapIconToComponent(
          index === 0 ? 'check' : index === 1 ? 'clock' : 'info'
        ),
        text: item.textContent || '',
      }));
    }

    const paragraphs = doc.querySelectorAll('p');
    if (paragraphs.length > 0) {
      return Array.from(paragraphs).map((p, index) => ({
        icon: mapIconToComponent(
          index === 0 ? 'info' : index === 1 ? 'map-pin' : 'calendar'
        ),
        text: p.textContent || '',
      }));
    }
  } catch (e) {
    console.error('Błąd parsowania HTML:', e);
  }

  const textWithoutTags = htmlDescription.replace(/<[^>]*>/g, ' ').trim();
  return [
    {
      icon: mapIconToComponent('info'),
      text: textWithoutTags,
    },
  ];
};

export const adaptDescription = (
  description: string | undefined
): { icon: React.ReactNode; text: string }[] | undefined => {
  if (!description) return undefined;

  if (description.includes('<') && description.includes('>')) {
    return convertHtmlDescriptionToBergFormat(description);
  }

  return convertDescriptionToBergFormat(description);
};
