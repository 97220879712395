import { clients, ClientId } from './clients';

export const widgetToClientMapping: Record<string, ClientId> = {
  '1': 'pngs',
};

export const getClientConfigFromWidgetId = (widgetId: string | null) => {
  if (!widgetId) return null;

  const clientId = widgetToClientMapping[widgetId];
  if (!clientId) return null;

  return {
    clientId,
    config: clients[clientId],
  };
};
