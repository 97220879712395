export default {
  theme: {
    colors: {
      primary: '#073a3f',
      secondary: '#32BA7C',
    },

    logo: '/assets/clients/pngs/logo.svg',
    headerText: 'Kup bilet wstępu do PNGS',
    client: 'Park Narodowy Gór Stołowych',
    phone: '48748661436',
    fonts: {
      primary: 'Lato, sans-serif',
    },
  },
  api: {
    baseUrl: process.env.REACT_APP_API_URL,
  },
  features: {
    showReviews: true,
    enableWishlist: true,
  },
};
