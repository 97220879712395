import React, { createContext, useContext } from 'react';
import { useClientConfigContext } from './ClientConfigContext';

interface Theme {
  colors: {
    primary: string;
    secondary: string;
    [key: string]: string;
  };

  fonts: {
    primary: string;
    [key: string]: string;
  };
  client: string;
  phone: string;
  logo: string;
  headerText: string;
}

interface ThemeContextType {
  theme: Theme;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { config } = useClientConfigContext();

  if (!config?.theme) {
    return null;
  }

  return (
    <ThemeContext.Provider value={{ theme: config.theme }}>
      <style>
        {`
          :root {
            --primary-color: ${config.theme.colors.primary};
            --secondary-color: ${config.theme.colors.secondary};
            --font-primary: ${config.theme.fonts.primary};
            ${Object.entries(config.theme.colors)
              .map(([key, value]) => `--color-${key}: ${value};`)
              .join('\n')}
          }
        `}
      </style>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within ThemeProvider');
  }
  return context;
};
