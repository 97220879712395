import axios from 'axios';
import i18n from '../context/i18n';

const axiosInstance = axios.create({
  responseType: 'json',
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 1000 * 20,
  headers: {
    'Accept-Language': i18n.resolvedLanguage,
    'Local-Authorization': process.env.REACT_APP_LOCAL_AUTHORIZATION,
  },
  paramsSerializer: { indexes: null },
});

axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.put['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.get['Content-Type'] = 'application/json';

i18n.on('languageChanged', (lng) => {
  axiosInstance.defaults.headers['Accept-Language'] = lng;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('API Error:', error);

    return Promise.reject(error);
  }
);

export default axiosInstance;
